<script setup>
defineProps({ blok: Object });

const { replaceHeadlineGradient } = useUtils();

onMounted(() => {
  replaceHeadlineGradient();
});
</script>
<template>
  <div v-editable="blok" class="animation-on-page-load-2">
    <strong v-if="blok?.pre_headline" class="pre-headline">{{
      blok.pre_headline
    }}</strong>
    <h1
      class="hero-heading gradient-heading"
      :class="{ small: blok?.image?.filename }"
      data-animate="fadeInUp"
      data-animate-delay="0"
    >
      {{ blok?.headline }}
    </h1>
  </div>
  <div
    class="hero-description-holder small-top-margin"
    :class="{
      'text-center': !blok.image?.filename,
      'pb-8': blok.image?.filename,
    }"
  >
    <p
      id="w-node-d6116216-2018-c43f-c07a-82f001922b40-4e2e13a0"
      class="hero-description"
      data-animate="fadeInUp"
      data-animate-delay="1"
    >
      {{ blok?.text }}
    </p>

    <slot />
  </div>

  <div
    v-if="blok?.buttons?.length"
    data-animate="fadeInUp"
    data-animate-delay="2"
  >
    <div class="hero-buttons">
      <div
        v-for="(link, index) in blok?.buttons"
        :key="index"
        class="hero-button-wrap"
      >
        <StoryblokComponent :blok="link" />
      </div>
      <div class="hero-buttons-line"></div>
      <div class="hero-buttons-line _2"></div>
    </div>
  </div>
</template>

<style scoped>
.pre-headline {
  font-size: 1.5rem;
  color: var(--1-main-colors--white);
  margin-bottom: 10px;
  display: inline-block;
}
h1.small {
  font-size: 86px;
}
@media screen and (max-width: 768px) {
  h1.small {
    font-size: 70px;
  }
}
@media screen and (max-width: 600px) {
  h1.small {
    font-size: 50px;
  }
  .pre-headline {
    font-size: 1.2rem;
  }
}
</style>
